

.points {

    /* background-color: red;    */
    min-height: 100vh;
    /* background-color: #e6e6e6; */
    padding: 0 30px;
 transition: var(--main-transition);

    
}

.points.dark-mode {
    background-color: var(--color-Main-dark);
}

.points .top-10 {
    margin-top: 150px;
}




.points .button-85 {
    padding: 0.8em 2.6em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: var(--color-Main-red);;
    /* cursor: pointer; */
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 1.4rem;
    font-weight: 600;
    width:fit-content ;
    margin: 0 auto;
  }
  
  .points .button-85:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity var(--main-transition) ease-in-out;
    border-radius: 10px;
  }
  
  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .points .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--color-Main-red);;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  
  .btn-topRated {
      text-align: center;
  }
  

  .react-confetti-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; 
  }

  .top-10 .top-10-named .info {
    background-color: #e9e9e9;
    /* display: flex;
    align-items: center; */
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    box-shadow: 0 0 10px #eee;
    transition: var(--main-transition);

    
  }

  .points.dark-mode .top-10 .top-10-named .info {
    background-color: var(--color-secondry-dark);
    box-shadow:none;
  }

  @media (max-width:767px) {
    .top-10 .top-10-named .info {
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        
      }
  }

  @media (min-width:767px) and (max-width:1023px) {
    .top-10 .top-10-named .info {
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        
      }
  }

  
  .top-10 .top-10-named .info img {
width: 50px;
height: 50px;
  }
  .top-10 .top-10-named .info .rank {
    font-size: 1.2rem;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 5px;
    color: var(--color-Main-red);
  }
  .top-10 .top-10-named .info h3 {
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 10px;
        transition: var(--main-transition);
  }

  .points.dark-mode  .top-10 .top-10-named .info h3 {
    color: white;
}


  .custom-progress-bar-avarage {
    margin-top: 20px;

  }

  .points .progress-bar {
    width: 100%;
    
    background-color: inherit;
    border-radius: 5px;
    margin-top: 5px;
    position: relative;
  }
  
 .points .progress {
    height: 20px;
    background-color: var(--color-Main-red);
    border-radius: 5px;
    position: relative;
  }
  
  .points .progress-text {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: white;
    text-align: center;
    /* left: 50%; */
  }



