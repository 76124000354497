

    .profile {
      background-color: #f3f4f6;
      padding: 180px 0 0 0;
      flex-direction: row-reverse;
      min-height: 100vh;
      transition: var(--main-transition);
    }



    .profile .row{
      flex-direction: row-reverse;
    }

    @media (max-width:767px) {
      .profile {
        padding: 120px  0 0 0;
        flex-direction: column;
      }
    .profile .row{
      
      flex-direction: row;
    }
    .profile .row:last-child{
      
      flex-direction:  column-reverse;
    }
    }

    .profile .sidebar-profile-main {
      background-color: #e2e4e7;
      position: relative;
      width: 300px;
      box-shadow: 0 0 10px #0003;
      padding: 30px 0;
      z-index: 9999999;
    
    }

    .profile .sidebar-profile-main h3 {
      position: relative;
    }

    .profile .sidebar-profile-main ul {
      padding: 0;
      list-style: none;
    }
   

    .profile .sidebar-profile-main a {
      display: flex;
      align-items: center;
      color: #000;
      text-align: left;
      padding: 15px 20px;
      text-decoration: none;
      justify-content: end;
      font-size: 1.2rem;
      font-weight: 500;
      transition: var(--main-transition);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      margin-bottom: 25px;
    }
    .profile .sidebar-profile-main a span {
      margin-right: 10px;
     
    }

    .profile .sidebar-profile-main a:hover {
      background-color: #0003;
    }

    @media (max-width:767px) {
      .profile .sidebar-profile-main {
        width: 100%;
        padding: 20px ;
        text-align: center;
        direction: ltr;
        z-index: 999999;
      }
      .profile .sidebar-profile-main span {
        /* display: none; */
      }

      .profile .sidebar-profile-main a:hover {
        background: none;
      }
      .profile .sidebar-profile-main ul {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .profile .sidebar-profile-main ul li {
        width: 100%;
      }
    }

    

    .profile .content-profile {
      overflow: hidden;
      width: 100%;
      padding: 30px;
    }
    .profile .content-profile .title-main{
        padding: 15px;
        background-color: #e2e4e7;
        /* box-shadow: 0 0 10px #ddd; */
        border-radius: 10px;
        text-align: center;
        box-shadow: 0 0 10px #0003;

    }

    .profile .content-profile .title-main h2 {
      font-size: 1.8rem;
      font-weight: 600;
    }
    .profile .content-profile .title-main h2 span{
        color: var(--color-Main-red);;
    }

   .profile .content-profile .personal-data{
      background-color: #e2e4e7;
      padding: 30px 30px;
      text-align: right;
      border-radius: 10px;
      /* box-shadow: 0 0 10px #ddd; */
      box-shadow: 0 0 10px #0003;

       }

   .profile .content-profile .personal-data h1 {
    font-weight: 500;
   position: relative;
   font-size: 2.1rem;
   letter-spacing: -0.5px;
   }

   .profile .content-profile .personal-data h1::before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    height: 3px;
    background-color: #ddd;
    width: 100%;
   }

   .profile .content-profile .personal-data span {
    display: block;
    font-size: 1.2rem;

   }

   .profile .content-profile .personal-data a {
    text-decoration: none;
    background-color: var(--color-Main-red);;
    color: #fff;
    padding: 10px 40px;
    margin-top: 30px;
    display: block;
    width: fit-content;
    margin: 25px 0 auto auto;
    border-radius: 6px;
    border: 2px solid var(--color-Main-red);;
    transition: var(--main-transition);
   }
   @media (max-width:767px) {
    .profile .content-profile .personal-data a {
      margin: 25px auto 0 auto;
     }
     .profile .content-profile .personal-data{
        text-align: center;
       }
   }
   .profile .content-profile .personal-data img {
    width:156px;
   }

   .profile .content-profile .personal-data a:hover {
    background:none;
    color: var(--color-Main-red);;
   }

   .profile .content-profile .personal-data .fa-phone {
    color: var(--color-Main-red);;
   }

   .profile .content-profile .personal-data .fa-envelope {
    color: var(--color-Main-blue);;
   }

   .profile .content-profile .statistic {
    background-color: #e2e4e7;
    padding: 45px 30px;
    text-align: right;
    border-radius: 10px;
    /* box-shadow: 0 0 10px #ddd; */
    box-shadow: 0 0 10px #0003;

   }


   .profile .content-profile .statistic .title  {
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .profile .content-profile .statistic .title h3 {
    text-align: center;
    -webkit-text-stroke-width: 1.5px;
    font-size: 2rem;
  }

   .profile .content-profile .statistic .title h3 span {
    color: var(--color-Main-red);;
   }

   .profile .content-profile .statistic .title img {
    width: 100px;
   }


  .profile .statistic .videos ,
  .profile .statistic .quizes,
  .profile .statistic .avarage {
    margin: 30px 0;
  }
 

  .profile .statistic .videos span,
  .profile .statistic .quizes span,
  .profile .statistic .avarage span{
    font-size: 1.2rem;
    font-weight: 600;
  }

  @media (max-width:767px) {
    .profile .statistic .videos span,
    .profile .statistic .quizes span,
    .profile .statistic .avarage span{
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .profile .statistic .videos span span {
    color: var(--color-Main-red);;
  }
  .profile .statistic .quizes span span {
    color: var(--color-Main-blue);;
  }
  .profile .statistic .avarage span span {
    color: #ffbf00;
  }

  .profile .custom-progress-bar-video, 
  .profile .custom-progress-bar-quizes, 
  .profile .custom-progress-bar-avarage {
      margin-top: 10px;
  }
  
  .profile .custom-progress-bar-video .progress-bar ,
  .profile .custom-progress-bar-quizes .progress-bar, 
  .profile .custom-progress-bar-avarage .progress-bar {

   box-shadow: inset 0 0 20px rgb(0 0 0 / 15%);
   font-size: 15px;
  
 }
  .profile .custom-progress-bar-video .progress-bar {
    background-color: var(--color-Main-red);;


 }
  .profile .custom-progress-bar-quizes .progress-bar {
    background-color: var(--color-Main-blue);;
 }
  .profile .custom-progress-bar-avarage .progress-bar {
    background-color: burlywood;
 }

 .profile .content-profile .statistic .number-video, 
 .profile .content-profile .statistic .number-quizes ,
 .profile .content-profile .statistic .number-avarage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
 }

 .profile .content-profile .statistic .number-video .num {
  color: var(--color-Main-red);;
  font-size: 1.4rem;
 }

 .profile .content-profile .statistic .number-quizes .num  {
  color: var(--color-Main-blue);;
  font-size: 1.4rem;
 }

 .profile .content-profile .statistic .number-avarge .num { 
  color: burlywood;
  font-size: 1.4rem;
 }

 .profile .content-profile .statistic .number-video .num span ,
 .profile .content-profile .statistic .number-quizes .num span ,
 .profile .content-profile .statistic .number-avarge .num span {
  color: #000;
  font-size: 1.4rem;
 }
  

.profile .content-profile .wallet {
  background-color: #e2e4e7;
  box-shadow: 0 0 10px #0003;

  /* box-shadow: 0 0 10px #ddd; */
  border-radius: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }

.profile .content-profile .wallet input{
    width: 100%;
    border: 2px solid #eab308
 }

 .profile .content-profile .wallet .title {
  text-align: center;
  margin-bottom: 10px;
 }

 .profile .content-profile .wallet .title h3{
  text-align: center;
    -webkit-text-stroke-width: 1.5px;
    font-size: 2rem;
 }

 @media (max-width:767px) {
  .profile .content-profile .wallet .title h3{
    font-size: 1.5rem;
 }
 }

 .profile .content-profile .wallet .title h3 span {
  color: var(--color-Main-red);;
 }

 .profile .content-profile .wallet  img {
  width: 200px;
 }


 .profile .content-profile .wallet .price {
  margin-top: 30px;
  margin-left: 7px;
  border: 2px solid #eab308;
  width: -moz-fit-content;
  width: fit-content;
  /* padding: 14px 0 14px 30px; */
  border-radius: 25px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.profile .content-profile .wallet .price span:first-child {
  background-color: #eab308;
  padding: 17px 29px 18px 30px;
  border-radius: 20px;
  margin-left: 15px;
}

.profile .content-profile .wallet .price   span:last-child {
  padding-left: 20px;
}

@media (max-width:767px) {
  .profile .content-profile .wallet .price span:first-child {
    padding: 10px;
    font-size: 0.8rem;
  }
  
}

.profile .content-profile .number-of-statistic {
 
  margin-bottom: 10px;
}

.profile .content-profile .number-of-statistic .num-weeks ,
.profile .content-profile .number-of-statistic .num-questions ,
.profile .content-profile .number-of-statistic .num-videos ,
.profile .content-profile .number-of-statistic .num-openvideos ,
.profile .content-profile .number-of-statistic .num-codes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  border-radius: 10px;
  padding: 14px 30px;
  background-color: #e2e4e7;
  margin-bottom: 50px;
    /* box-shadow: 0 0 10px #ddd; */
  box-shadow: 0 0 10px #0003;
}

.profile .content-profile .number-of-statistic .num-weeks span,
.profile .content-profile .number-of-statistic .num-questions span ,
.profile .content-profile .number-of-statistic .num-videos span ,
.profile .content-profile .number-of-statistic .num-openvideos span ,
.profile .content-profile .number-of-statistic .num-codes span {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: -0.5px;
}

  @media (max-width:767px) {
    .profile .content-profile .number-of-statistic .num-weeks span,
.profile .content-profile .number-of-statistic .num-questions span ,
.profile .content-profile .number-of-statistic .num-videos span ,
.profile .content-profile .number-of-statistic .num-openvideos span ,
.profile .content-profile .number-of-statistic .num-codes span {
  font-size: 1.2rem;
  text-align: right;
}
  }


.profile .content-profile .number-of-statistic .num-weeks span:last-child{
    color: var(--color-Main-red);;
    font-weight: 600;
}
.profile .content-profile .number-of-statistic .num-questions span:last-child{
    color: var(--color-Main-blue);;
    font-weight: 600;
}
.profile .content-profile .number-of-statistic .num-videos span:last-child{
    color: #ffbf00;
    font-weight: 600;
}
.profile .content-profile .number-of-statistic .num-openvideos span:last-child{
    color: var(--color-Main-red);;
    font-weight: 600;
}
.profile .content-profile .number-of-statistic .num-codes span:last-child{
    color: var(--color-Main-blue);;
    font-weight: 600;
}


.profile.dark-mode,
.profile.dark-mode .sidebar-profile-main a {
  background-color: var(--color-Main-dark);;
  transition: var(--main-transition);
  color: white;
}

.profile.dark-mode .sidebar-profile-main,
.profile.dark-mode .content-profile .title-main,
.profile.dark-mode  .content-profile .personal-data,
.profile.dark-mode  .content-profile .statistic,
.profile.dark-mode  .content-profile .wallet ,
.profile.dark-mode .content-profile .number-of-statistic .num-weeks ,
.profile.dark-mode .content-profile .number-of-statistic .num-questions ,
.profile.dark-mode .content-profile .number-of-statistic .num-videos ,
.profile.dark-mode .content-profile .number-of-statistic .num-openvideos ,
.profile.dark-mode .content-profile .number-of-statistic .num-codes{
  background-color: var(--color-secondry-dark);;
  transition: var(--main-transition);
  color: white;
  box-shadow: -5px 5px 20px #0006;
  transition: var(--main-transition);


}

.profile.dark-mode .sidebar-profile-main a {
  color: white;
}

.profile.dark-mode .sidebar-profile-main a:hover{
  background-color: #1f2937;
}


.profile.dark-mode .content-profile .statistic .number-video .num span ,
.profile.dark-mode .content-profile .statistic .number-quizes .num span ,
.profile.dark-mode .content-profile .statistic .number-avarge .num span {
 color: #fff;
 font-size: 1.4rem;
}

.profile .content-profile .go-modelanswer {
    text-decoration: none;
    color: #fff;
    background-color: var(--color-Main-red);;
    padding: 6px 20px;
    border-radius: 6px;
}

.profile .content-profile table {

}
.profile .content-profile .main-table {
 overflow-x: hidden;
 background-color: #fff;
 box-shadow: 0 0 10px #ddd;
  border-radius: 10px;
 padding: 30px;
 direction: rtl;

 
}

.profile.dark-mode .content-profile table td{
  color: #fff;
  background-color: var(--color-Main-dark);;
}
.profile.dark-mode .content-profile .main-table {
  color: #fff;
  background-color: var(--color-secondry-dark);;
  box-shadow: -5px 5px 20px #0006;
}

.profile .content-profile table {
 border: 2px solid #ffaaaa;
 text-align: center;
 direction: rtl;
 min-width: 1600px;
 width: 100%;

}

.profile .content-profile table td {
  background-color: #e9e9e9;
}

.profile .content-profile table tr:hover{
background-color: #777;
transition: var(--main-transition);
color: #fff;
}
 
.profile .react-calendar {
  width: 80%;
  /* margin: 0 auto; */
}

@media (max-width:767px) {
  .profile .react-calendar {
    width: 100%;
  }
}

.profile.dark-mode .react-calendar {
  color: #fff;
  background-color: var(--color-Main-dark);;
}

.profile.dark-mode .react-calendar button{
  color: #fff;
}

.profile.dark-mode .react-calendar button:hover{
  color: #000;
  background-color: #fff;
}

.profile.dark-mode .content-profile .card{
  color: #fff;
  background-color: var(--color-secondry-dark);;
  transition: var(--main-transition);
}

.profile.dark-mode .content-profile .card  .dates  span{
  color: #9ca3af;
}


.non-courses h2{
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.4;

}

.non-courses h2 span {
  color: var(--color-Main-red);;
}

@media (max-width:767px) {
.non-courses h2{
  line-height: 1.5;
  font-size: 1.7rem;
}
.loading-coursess h2 {
font-size: 1.7rem;

}
}

.page-link {
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  padding: 8px 12px;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}

.pagination li {
  margin: 0 10px;
}

.pagination li a {
  color: #007bff;
  font-size: 16px;
  text-decoration: none;
}

.pagination li.active a {
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  padding: 8px 12px;
}






