

.register-user {
    min-height: 100vh;
    background-color: var(--color-Main-background);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 200px 0;
    transition: var(--main-transition);
}

.register-user.dark-mode {
    background-color: var(--color-Main-dark);;
    transition: var(--main-transition);
}

.register-user.dark-mode form {
  background-color: var(--color-secondry-dark);
  transition: var(--main-transition);
  color: #fff;
  box-shadow: -5px 5px 20px #0006;

}

.register-user.dark-mode form button {
  border: 2px solid var(--color-secondry-dark);
}

.register-user.dark-mode form button:hover {
  background-color: var(--color-secondry-dark);
  border: 1px solid #fff;
}

.register-user .box {
    /* box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 20px 20px;
    position: relative;
    border-radius: 20px; */
    border-radius: 20px;
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 0;
} 

@media (max-width:767px) {
  .register-user .box {
    flex-direction: column-reverse;
  }
    
}

.register-user form {
    background-color: #fff;
    /* border-radius: 20px; */
    /* width: 50%; */
    padding: 50px 30px;
    border-radius: 10px 0 0 10px;
    direction: rtl;
}

.register-user form h2{
  text-align: center;
  font-weight:500 ;
  -webkit-text-stroke-width: 1.7px;

}

.register-user .welcome-sign{
  /* width: 50%; */
  /* background: rgb(253,29,29);
  background: linear-gradient(90deg, rgba(253,29,29,1) 0%, rgba(255,101,101,1) 50%, rgba(255,185,72,1) 100%); */

background: #ECECEC;
background: linear-gradient(135deg, #ECECEC, #ee552b);
  border-radius: 0 10px 10px 0;
  display: flex ;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 50px 30px;

  
}
.register-user .welcome-sign h2{
  font-weight: 600;
  font-size: 2rem;
  color: #ffffff;
}
.register-user form h2 span {
  color: #e02a24;
}
.register-user .welcome-sign p{
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
}
.register-user .welcome-sign a{
    color: white;
    text-decoration: none;
    padding: 15px 30px;
    border: 2px solid #ffffff;
    border-radius: 20px;
    transition: var(--main-transition);
    font-weight: 600;
}

.register-user .welcome-sign a:hover {
  background-color: rgba(255, 255, 255 );
  color: #ee552b;
  border: 2px solid none;
}

.register-user .container {
    margin: 0 auto;
}

.register-user .input-group {
    display: flex;
    justify-content: space-between;
}

.register-user .input-group .big-input {
    width: 49%;
}

.register-user label.form-label {
    font-weight: 600;
}

.register-user  input{
    width: 100%;
    margin-bottom: 5px;
    padding: 15px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
}

.register-user .label {
  font-weight: 600;
}

.register-user  input:focus {
  /* outline: 1px solid var(--color-Main-red);; */
  outline: none;
  }


.register-user select {
    background-color: #f9f9f9;
}

.register-user option {
    color: #000;

}

/* Add your other styles */

/* Custom styles for the swal message */
.custom-swal {
  font-size: 18px; /* Adjust the font size as needed */
}

.custom-swal .swal-text {
  color: #e02a24;
}


.register-user button {
  padding: 15px 35px;
  /* background-color: white; */
/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=ececec&c2=06b6d4&gt=l&gd=dtl
*/
background: #ECECEC;
background: linear-gradient(135deg, #ECECEC, #ee552b);
  border: 1px solid #fff;
  border-radius: 15px ;
  transition: var(--main-transition);
  color: #ffffff;
}

.register-user button:hover  {
  background: #fff;
  color: #ee552b;
  border: 1px solid #ee552b;
}

.register-user .error {
    color: red;
    font-weight: 500;
    font-size: 16px;
}

.register-user .box .register{
  font-size: 1.1rem;
  text-decoration: none;
  color: #e02a24;
  font-weight: 600;
  transition: var(--main-transition);
  display: block;
}

.register-user .box .register:hover {
  padding-left: 20px;
  font-weight: 800;
}



@media (max-width:767px){
    .register-user .input-group {
        display: flex;
        flex-direction: column;
    }
    .register-user .input-group .big-input {
        width: 100%;
    }
}


.password-input {
  position: relative;
}

/* Add this CSS to your existing styles */
.password-toggle {
  position: absolute;
  /* top: 50%; */
  left: 20px;
  transform: translateY(-175%);
  cursor: pointer;
  color: #777; /* Adjust the color of the icon */
  transition: color 0.3s ease-in-out;
}

.password-toggle:hover {
  color: #333; /* Change color on hover */
}

